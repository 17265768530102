<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard no-header>
        <CCardBody>
          <CForm v-if="user">
            <template slot="header">
              Edit User id:  {{ $route.params.id }}
            </template>
            <CAlert
              :show.sync="dismissCountDown"
              color="primary"
              fade
            >
              ({{dismissCountDown}}) {{ message }}
            </CAlert>
            <CInput type="text" label="Name" id="company" placeholder="Name" v-model="user.name"></CInput>
            <CInput type="text" label="Email" id="email" placeholder="Email" v-model="user.email"></CInput>
            <CInput type="text" label="Customer Number" id="customer_number" placeholder="Customer #" v-model="user.customer_number"></CInput>
            <div style="width:100%;float:left;margin-bottom:15px;">
              <label>User Type</label>
              <v-select 
                v-model="user.user_type"
                :options="['in_house', 'customer']"
                placeholder="User Type"
              />
            </div>

            <CInput
              type="text"
              label="User Type"
              style="margin-top:15px;"
              v-if="user.user_type === 'in_house'"
              :lazy="false"
              :value.sync="user.csr_code"
              placeholder="CSR Code"
              invalidFeedback="This is a required field."
            />

            <CInput
              type="text"
              label="Rep Number"
              style="margin-top:15px;"
              v-if="user.user_type === 'customer'"
              :lazy="false"
              :value.sync="user.rep_number"
              placeholder="Rep Number"
              invalidFeedback="This is a required field."
            />

            <div style="width:100%;float:left;">
              <label>CSR</label>
              <v-select 
                v-if="csrOptions && user.user_type === 'customer'"
                v-model="user.csr"
                :options="csrOptions"
                label="name"
                value="id"
                placeholder="CSR"
              />
            </div>
            <p class="mt-3">Roles</p>
            <div class="m-3 mt-2" v-if="roles">
              <CInputCheckbox
                  v-for="rol in roles"
                  :key="rol"
                  :label="rol"
                  name="selectRoles"
                  :checked="user.menuroles.indexOf(rol) > -1"
                  @update:checked="selectRadioSelectRole(rol)"
              />
            </div>

            <CInput
              type="text"
              label="Reset Password"
              style="margin-top:15px;"
              :lazy="false"
              :value.sync="reset_password"
              placeholder="Reset Password"
            />
            <CButton color="primary" @click="update()">Save</CButton>
            <CButton color="primary" @click="goBack">Back</CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EditUser',
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
        user: null,
        name: '',
        roles: null,
        email: '',
        menuroles: '',
        user_type: '',
        showMessage: false,
        message: '',
        reset_password: '',
        dismissSecs: 7,
        dismissCountDown: 0,
        showDismissibleAlert: false,
        csrOptions: null
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    update() {
        let self = this;
        axios.post( this.$apiAdress + '/api/users/' + self.$route.params.id + '?token=' + localStorage.getItem("api_token"),
        {
            _method: 'PUT',
            name: self.user.name,
            email: self.user.email,
            user_type: self.user.user_type,
            csr_code: self.user.csr_code,
            customer_number: self.user.customer_number,
            menuroles: self.user.menuroles,
            reset_password: self.reset_password
        })
        .then(function (response) {
            self.message = 'Successfully updated user.';
            self.showAlert();
        }).catch(function (error) {
            console.log(error);
            // self.$router.push({ path: '/login' });
        });
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    selectRadioSelectRole(role){
      let temp = this.user.menuroles.indexOf(role); 
      if (temp > -1) {
        this.user.menuroles.splice(temp, 1);
      }else{
        this.user.menuroles.push(role);
      }
    },
    getRoles (){
      let self = this;
      axios.get(   this.$apiAdress + '/api/roles?token=' + localStorage.getItem("api_token") )
      .then(function (response) {
        self.roles = []
        response.data.forEach(function (val) {
          self.roles.push(val.name)
        })
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    },
    getCsrs (){
      let self = this;
      axios.get(   this.$apiAdress + '/api/getCsrs?token=' + localStorage.getItem("api_token") )
      .then(function (response) {
        self.csrOptions = response.data
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    }
  },
  mounted: function(){
    let self = this;
    this.getRoles()
    this.getCsrs()
    axios.get( this.$apiAdress + '/api/users/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
    .then(function (response) {
      console.log(response)
        self.user = response.data;
    }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
    });
  }
}


</script>
